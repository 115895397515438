import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

const styles = {
  wrapper: {
    height: '800px',
  },
  container: {
    height: '100% !important',
  },
  imagebox: {
    height: '100%',
    width: '100%',
  },
};

const getProxiedImageUrl = imageUrl =>
  `/.netlify/functions/image-proxy?url=${encodeURIComponent(imageUrl)}?q=10`;

export const Background = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url("${getProxiedImageUrl(props.image)}")`};
`;

function FourImageGrid(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <Box className={classes.wrapper}>
        <div className={`${classes.container} spaced-grid-wrapper`}>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  <Background
                    className={classes.imagebox}
                    image={data.tile1_image.url}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Background
                    className={classes.imagebox}
                    image={data.tile2_image.url}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Background
                    className={classes.imagebox}
                    image={data.tile3_image.url}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  <Background
                    className={classes.imagebox}
                    image={data.tile4_image.url}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Container>
  );
}

FourImageGrid.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FourImageGrid);
