import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TitleCaptionText } from 'components/slices';
import { withStyles } from '@material-ui/styles';
import { ButtonOutlineLight } from 'components/ui';
import linkResolver from 'utils/linkResolver';

const styles = {
  image: {
    maxWidth: '100%',
    width: '100%',
  },
  overlay: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

function ImageRightTextLeft(props) {
  const { primary, fields, classes } = props;
  return (
    <Container maxWidth={false}>
      <Grid container alignItems="center">
        <Box
          component={Grid}
          item
          xs={12}
          md={6}
          order={{ xs: 2, md: 1 }}
          position="relative"
        >
          <img
            className={classes.image}
            src={`/.netlify/functions/image-proxy?url=${primary.image.url}&q=10&lossless=1`}
            alt={primary.image.alt}
          />
          <Box
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            className={classes.overlay}
            p={4}
          >
            <Box />
            <Box>
              {primary.image_title && (
                <Typography component="p" variant="h4" color="secondary">
                  {primary.image_title[0].text}
                </Typography>
              )}
            </Box>
            <Box>
              {primary.image_link_title && primary.image_link && (
                <ButtonOutlineLight
                  text={primary.image_link_title[0].text}
                  link={`${
                    primary.image_link._meta
                      ? linkResolver(primary.image_link._meta)
                      : '/'
                  }`}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box component={Grid} item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box px={[0, 2, 2, 16]}>
            <TitleCaptionText
              data={primary}
              titlecomponent="h4"
              xs={12}
              md={12}
              lg={12}
            />
            {fields.length > 0 && (
              <Box component={Grid} container>
                {fields.map((item, key) => (
                  <Grid item xs={4} sm key={key}>
                    <Box p={2}>
                      <img
                        src={`/.netlify/functions/image-proxy?url=${item.inline_images.url}&q=10&lossless=1`}
                        alt={item.inline_images.alt}
                        className={classes.image}
                      />
                    </Box>
                  </Grid>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}

ImageRightTextLeft.propTypes = {
  primary: PropTypes.object.isRequired,
  fields: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageRightTextLeft);
