import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TitleCaptionText } from 'components/slices';
import { ButtonBlockDark } from 'components/ui';
import { withStyles } from '@material-ui/styles';
import linkResolver from 'utils/linkResolver';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const styles = {
  image: {
    maxWidth: '100%',
  },
};

function TextLeftImageRight(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <Box px={[0, 2, 2, 16]}>
            <TitleCaptionText
              data={data}
              titlecomponent="h4"
              xs={12}
              md={12}
              lg={12}
            />
            {data.link && data.link._meta && (
              <Box my={4} display="flex" justifyContent="center">
                <ButtonBlockDark
                  text="READ MORE"
                  link={`${data.link ? linkResolver(data.link._meta) : ''}`}
                  icon={<ArrowForwardIosIcon />}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            className={`${classes.image} lazyload`}
            data-src={`/.netlify/functions/image-proxy?url=${data.image.url}&q=10&lossless=1`}
            alt={data.image.alt}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

TextLeftImageRight.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextLeftImageRight);
